import { CustomModal } from '../../components/modal'
import { SearchBar } from '../../components/searchbar'
import { DataTable } from '../../components/table'
import { RootState } from '../../store'
import { SubscriptionColumns, SubscriptionDetailsForm } from './components'
import './subscriptions.scss'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { setSubscriptionSearchText } from '../../constants/reducer/subscription-reducer'
import { Subscription } from '../../constants/entity'

export const Subscriptions = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { subscriptions, searchText } = useSelector( ( state: RootState ) => state.message )
    const [ state, setState ] = useState( { modalVisible: false } )

    const filterer = ( subscription: Subscription ) => {
        return subscription.name.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1 || subscription.email.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1 || subscription.company.toLowerCase().indexOf( searchText.toLowerCase() ) !== -1
    }


    const handleModalVisible = () => {
        setState( ( prevState ) => ( {
            ...prevState, modalVisible: !state.modalVisible
        } ) )
    }

    const exportCSV = () => {

        const csv = Papa.unparse( subscriptions )
        const csvData = new Blob( [ csv ], { type: 'text/csv' } )
        const csvUrl = URL.createObjectURL( csvData )
        const a = document.createElement( 'a' )
        a.href = csvUrl
        a.download = `feliratkozok-${ new Date().getTime() }.csv`
        document.body.appendChild( a )
        a.click()
        document.body.removeChild( a )

    }

    return (

        <div className="page subscriptions-page">

            <div className="title-text-h2">

                { t( 'subscriptions-page-page-title' ) }

                <button className="export-button" onClick={ exportCSV }>
                    { t( 'message-page-export-button-title' ) }
                </button>

            </div>

            <div className="searchbar-container">
                <SearchBar value={ searchText } onChange={ ( text: string ) => dispatch( setSubscriptionSearchText( text ) ) } placeholder={ t( 'subscriptions-page-searchbar-placeholder' ) }/>
            </div>

            <DataTable columns={ Object.values( SubscriptionColumns( { handleModalVisible } ) ) } dataSource={ subscriptions.filter( filterer ) }/>

            <CustomModal modalVisible={ state.modalVisible } onModalClose={ handleModalVisible } title={ 'subscriptions-page-subscription-details-modal-title' } isDetail={ true }>

                <SubscriptionDetailsForm/>

            </CustomModal>

        </div>

    )

}
